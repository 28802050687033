<template lang="pug">
.page-services
  .t-h1-mobile.c-gray100.mb-6 Сервисы
  template(v-if="!loading")
    .page-services__list(v-if="sortedServices")
      service-card.card-sheet.mb-4(
        v-for="service in sortedServices"
        :key="service.name"
        :service="service"
      )
        .service-card-footer.mt-4
          div(v-if="service.tariff_kind !== 'without_cost'")
            .t-caption-xsmall.c-gray60.mb-1 Стоимость
            .t-h3.c-gray100 {{ service.tariff_price_formatted }}
          ui-btn.ml-auto(
            plain
            secondary
            text
            :to="$path.service(service.name)"
          ) Подробнее
  // идёт загрузка
  template(v-else)
    v-overlay(color="white" opacity="1")
      ui-loader.my-6.px-4(big text="Загрузка...")
</template>

<script>
  import { UiIcon, UiBtn, UiBadge, UiLoader } from '@/uikit';
  import AppLayout from '@/layouts/AppLayout';
  import ServiceCard from '@/components/ServiceCard.vue';
  import { customSortServices, service as API } from '@/services/services';

  import { handleError } from '@/libs/handle-error';
  import { createLogger } from '@/uikit/util/logger';
  const logger = createLogger('PageServices', 'blue');

  export default {
    components: {
      UiIcon,
      UiBtn,
      UiBadge,
      UiLoader,
      AppLayout,
      ServiceCard,
    },

    data: () => ({
      loading: true,
      services: undefined,
    }),

    computed: {
      sortedServices() {
        return this.services ? customSortServices(this.services) : undefined;
      },
    },

    mounted() {
      this.fetchServices();
    },

    methods: {
      async fetchServices() {
        try {
          this.loading = true;
          this.services = await API.getServices();
        } catch (error) {
          handleError(error, logger);
        } finally {
          this.loading = false;
        }
      },
    },
  };
</script>

<style lang="scss">
  .page-services {
    padding: 32px 16px;
  }

  .service-card-footer {
    display: flex;
    width: 100%;
    padding-top: 20px;
    height: 64px;
    border-top: 1px solid c('gray10');
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .service-card-footer__price {
    flex-grow: 1;
    flex-basis: 50%;
  }

  .service-card-footer__btn {
    flex-shrink: 1;
    margin-left: auto;
  }
</style>
